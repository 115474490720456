import React, { useState } from 'react';
import { auth, db, storage } from '../../config';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useNavigate } from 'react-router-dom';
import '../../styles/modal_sign.css';

const ModalSign = ({ isOpen, onClose }) => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [file, setFile] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [isLogin, setIsLogin] = useState(true);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleAuth = async (e) => {
        e.preventDefault();
        setErrorMessage('');
        setSuccessMessage('');
        try {
            let user;
            let photoURL = null;

            const displayName = email.substring(0, email.indexOf('@'));

            if (!isLogin) {
                const result = await createUserWithEmailAndPassword(auth, email, password);
                user = result.user;

                if (file) {
                    const fileRef = ref(storage, `userPhotos/${user.uid}/${file.name}`);
                    await uploadBytes(fileRef, file);
                    photoURL = await getDownloadURL(fileRef);
                }

                await setDoc(doc(db, "users", user.uid), {
                    uid: user.uid,
                    email: user.email,
                    displayName: displayName,
                    photoURL: photoURL,
                    lastLogin: new Date()
                }, { merge: true });

            } else {
                const result = await signInWithEmailAndPassword(auth, email, password);
                user = result.user;

                const userDoc = await getDoc(doc(db, "users", user.uid));
                if (userDoc.exists()) {
                    photoURL = userDoc.data().photoURL;
                }
            }

            document.cookie = `uid=${user.uid}; path=/; max-age=86400;`;
            document.cookie = `displayName=${displayName || user.email}; path=/; max-age=86400;`;
            document.cookie = `email=${user.email}; path=/; max-age=86400;`;
            document.cookie = `photoURL=${photoURL || ''}; path=/; max-age=86400;`;

            localStorage.setItem('displayName', displayName || user.email);
            localStorage.setItem('photoURL', photoURL || '');

            onClose();

            navigate('/home');
        } catch (err) {
            switch (err.code) {
                case 'auth/user-not-found':
                    setErrorMessage('Осы электрондық поштасы бар пайдаланушы табылмады.');
                    break;
                case 'auth/wrong-password':
                    setErrorMessage('Қате құпия сөз.');
                    break;
                case 'auth/weak-password':
                    setErrorMessage('Әлсіз пароль. Басқасын қолданып көріңіз.');
                    break;
                case 'auth/email-already-in-use':
                    setErrorMessage('Бұл электрондық пошта бұрыннан қолданыста.');
                    break;
                default:
                    setErrorMessage('Қате орын алды. Қайталап көріңіз.');
            }
        }
    };

    const handlePasswordReset = async () => {
        setErrorMessage('');
        setSuccessMessage('');

        if (!email) {
            setErrorMessage('Электрондық поштаны енгізіңіз.');
            return;
        }

        try {
            await sendPasswordResetEmail(auth, email);
            setSuccessMessage('Құпия сөзді қалпына келтіру сілтемесі сіздің электрондық поштаңызға жіберілді.');
        } catch (err) {
            switch (err.code) {
                case 'auth/user-not-found':
                    setErrorMessage('Осы электрондық поштасы бар пайдаланушы табылмады.');
                    break;
                default:
                    setErrorMessage('Қате орын алды. Қайталап көріңіз.');
            }
        }
    };

    const handleSignupClick = () => {
        setIsLogin(false);
    };

    const handleLoginClick = () => {
        setIsLogin(true);
    };

    return (
        isOpen && (
            <div className="modal_signin_container">
                <div className="modal_signin_content">
                    <p className="close-modal" onClick={onClose}>&times;</p>
                    <div className="title-text">
                        <div className={`title ${isLogin ? "login" : "signup"}`}>
                            {isLogin ? "Кіру" : "Тіркелу"}
                        </div>
                    </div>
                    <div className="form-container">
                        <div className="slide-controls">
                            <input type="radio" name="slide" id="login" checked={isLogin} readOnly />
                            <input type="radio" name="slide" id="signup" checked={!isLogin} readOnly />
                            <label htmlFor="login" className="slide login" onClick={handleLoginClick}>
                                Кіру
                            </label>
                            <label htmlFor="signup" className="slide signup" onClick={handleSignupClick}>
                                Тіркелу
                            </label>
                            <div className="slider-tab"></div>
                        </div>
                        <div className="form-inner">
                            {isLogin ? (
                                <form action="#" className="login" onSubmit={handleAuth}>
                                    <div className="field">
                                        <input
                                            type="email"
                                            placeholder="Электрондық пошта"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="field">
                                        <input
                                            type="password"
                                            placeholder="Құпия сөз"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="pass-link">
                                        <p onClick={handlePasswordReset}>Құпия сөзіңізді ұмыттыңыз ба?</p>
                                    </div>
                                    <div className="field btn">
                                        <div className="btn-layer"></div>
                                        <input type="submit" value="Кіру" />
                                    </div>
                                </form>
                            ) : (
                                <form action="#" className="signup" onSubmit={handleAuth}>
                                    <div className="field">
                                        <input
                                            type="email"
                                            placeholder="Электрондық пошта"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="field">
                                        <input
                                            type="password"
                                            placeholder="Құпия сөз"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="field">
                                        <label className="input-file">
                                            <input
                                                type="file"
                                                onChange={handleFileChange}
                                            />
                                            <span>Сурет таңдаңыз</span>
                                        </label>
                                    </div>
                                    <div className="field btn">
                                        <div className="btn-layer"></div>
                                        <input type="submit" value="Тіркелу" />
                                    </div>
                                </form>
                            )}
                            {errorMessage && <div className="message">{errorMessage}</div>}
                            {successMessage && <div className="message">{successMessage}</div>}
                        </div>
                    </div>
                </div>
            </div>
        )
    );
};

export default ModalSign;