import React, { useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import Home from './ui/Home'; // Басты экран компоненті
import Books from './ui/Books'; // Кітаптар экраны
import User from './ui/User'; // Қолданушы профилі экраны
import Fooder from './ui/Fooder'; // Төменгі навигация компоненті
import PayInfoModal from './modals/PayInfoModal'; // Төлем туралы модаль

import { db } from '../config'; // Firebase конфигурациясы
import FooderControl from './components/FooderControl'; // Аудио плеерді басқару компоненті

function Main() {
    // Қойынды (таб) күйі: 'home', 'books', 'user'
    const [activeTab, setActiveTab] = useState('home');
    // Аудио тізімі
    const [audioList, setAudioList] = useState([]);
    // Қазіргі ойналып жатқан аудио индексі
    const [currentAudioIndex, setCurrentAudioIndex] = useState(0);
    // Аудио плеер көріну күйі
    const [isAudioPlayerVisible, setAudioPlayerVisible] = useState(false);
    // Аудио ойнату күйі
    const [isPlaying, setIsPlaying] = useState(true);
    // Төлем туралы модаль күйі
    const [isModalVisible, setIsModalVisible] = useState(false);
    // Автор мәліметі
    const [author, setAuthor] = useState(null);
    // Аудио сурет URL-і
    const [imageUrl, setImageUrl] = useState(null);
    // Ағымдағы кітап ID-і
    const [bookId, setBookId] = useState(null);
    // Қолданушы фотосуреті мен ID-ін localStorage-ден алу
    const userPhotoUrl = JSON.parse(localStorage.getItem('user'))?.photoUrl;
    const userId = JSON.parse(localStorage.getItem('user'))?.uid;
    // Қате модаль күйі
    const [isErrorModalVisible, setIsErrorModalVisible] = useState(false);

    // Қолданушы ақпаратын тексеру
    useEffect(() => {
        const checkUserInfo = async () => {
            if (userId) {
                try {
                    // Firebase-ден қолданушы деректерін алу
                    const userDoc = await getDoc(doc(db, 'users', userId));
                    const userData = userDoc.exists() ? userDoc.data() : {};
                    const phoneNumber = userData.phoneNumber || '';

                    // Егер телефон нөмірі мен карта мәліметтері болмаса, модальды ашу
                    if (!phoneNumber) {
                        setIsModalVisible(true);
                    } else {
                        setIsModalVisible(false);
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            } else {
                console.log('User is not authenticated');
            }
        };

        checkUserInfo(); // Функцияны шақыру
    }, [userId]);

    // URL-дің `hash` өзгерісін бақылау
    useEffect(() => {
        const checkHashForError = () => {
            // Егер `#error` болса және белсенді қойынды 'home' болса, қате модалін көрсету
            if (window.location.hash === '#error' && activeTab === 'home') {
                setIsErrorModalVisible(true);
            } else {
                setIsErrorModalVisible(false);
            }
        };

        checkHashForError(); // Бастапқы тексеру
        window.addEventListener('hashchange', checkHashForError); // `hashchange` оқиғасын тыңдау

        return () => {
            window.removeEventListener('hashchange', checkHashForError); // Тыңдауды алып тастау
        };
    }, [activeTab]);

    // Қойындыны ауыстыру
    const handleTabChange = (tab) => {
        setActiveTab(tab); // Жаңа белсенді қойындыны күйге сақтау
        if (tab !== 'home') {
            setIsErrorModalVisible(false); // Қойынды өзгерсе, қате модалін жасыру
        }
    };

    // Аудионы ойнату
    const handlePlayAudio = (id, audioUrls, audioNames, author, imageUrl) => {
        setBookId(id); // Кітап ID-ін күйге сақтау
        setAudioList(audioUrls.map((url, index) => ({ url, name: audioNames[index] }))); // Аудио тізімін күйге сақтау
        setCurrentAudioIndex(0); // Бірінші аудиодан бастау
        setAudioPlayerVisible(true); // Аудио плеерді көрсету
        setIsPlaying(true); // Ойнату күйін орнату
        setAuthor(author); // Авторды күйге сақтау
        setImageUrl(imageUrl); // Сурет URL-ін күйге сақтау
    };

    // Келесі аудионы ойнату
    const handleNextAudio = () => {
        setCurrentAudioIndex((prevIndex) => (prevIndex + 1) % audioList.length); // Келесі аудио индексін есептеу
        setIsPlaying(true); // Ойнату күйін жаңарту
    };

    // Алдыңғы аудионы ойнату
    const handlePrevAudio = () => {
        setCurrentAudioIndex((prevIndex) =>
            prevIndex === 0 ? audioList.length - 1 : prevIndex - 1
        );
        setIsPlaying(true);
    };

    // Ойнату/тоқтату
    const togglePlay = () => {
        setIsPlaying((prevIsPlaying) => !prevIsPlaying); // Ойнату күйін ауыстыру
    };

    // Белсенді қойындының мазмұнын көрсету
    const renderContent = () => {
        switch (activeTab) {
            case 'home':
                return <Home handlePlayAudio={handlePlayAudio} handleReadButtonClick={() => {
                    setIsPlaying(false);
                    if ('mediaSession' in navigator) {
                        navigator.mediaSession.metadata = null;
                    }
                }}
                />;
            case 'books':
                return <Books handleReadButtonClick={() => {
                    setIsPlaying(false);
                    if ('mediaSession' in navigator) {
                        navigator.mediaSession.metadata = null;
                    }
                }}
                />;
            case 'user':
                return <User handleReadButtonClick={() => {
                    setIsPlaying(false);
                    if ('mediaSession' in navigator) {
                        navigator.mediaSession.metadata = null;
                    }
                }}
                />;
            default:
                return <Home handleReadButtonClick={() => {
                    setIsPlaying(false);
                    if ('mediaSession' in navigator) {
                        navigator.mediaSession.metadata = null;
                    }
                }}
                />;
        }
    };

    return (
        <div className="main_container">
            {renderContent()}
            <Fooder
                activeTab={activeTab}
                userPicture={userPhotoUrl}
                handleTabChange={handleTabChange}
            />
            {isAudioPlayerVisible && (
                <FooderControl
                    userId={userId}
                    id={bookId}
                    audioUrl={audioList[currentAudioIndex]?.url}
                    audioName={audioList[currentAudioIndex]?.name}
                    author={author}
                    imageUrl={imageUrl}
                    onNext={handleNextAudio}
                    onPrev={handlePrevAudio}
                    isPlaying={isPlaying}
                    onPlay={togglePlay}
                />
            )}
            {isModalVisible && (
                <PayInfoModal
                    isOpen={isModalVisible}
                    onClose={() => setIsModalVisible(false)}
                />
            )}
            {isErrorModalVisible && (
                <div className="error-modal">
                    <h2>Қателік</h2>
                    <p>Сайтта қателік орын алды. Бұны сіз профил бөлімінде техникалық қызметке хабарлай аласыз.</p>
                    <button onClick={() => {
                        setIsErrorModalVisible(false);
                        if (window.location.hash) {
                            window.history.replaceState(null, '', window.location.pathname);
                        }
                    }}>Жабу</button>
                </div>
            )}
        </div>
    );
}

export default Main;