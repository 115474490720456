import axios from 'axios';
import qs from 'qs';
import jsSHA from 'jssha';

const getRandom = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;
const pad = (num) => num.toString().padStart(2, '0');

const getTimestamp = () => {
    const t = new Date();
    return `${t.getUTCFullYear()}${pad(t.getUTCMonth() + 1)}${pad(t.getUTCDate())}${pad(t.getUTCHours())}${pad(t.getUTCMinutes())}${pad(t.getUTCSeconds())}`;
}

const getRandomHex = (length) => {
    const characters = "ABCDEF0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
}

const generatePaymentRequestData = (uid, bookId, bookPrice) => {
    const order = getRandom(10000000, 99999999);
    const timestamp = getTimestamp();
    const nonce = getRandomHex(32);
    const merchRNID = getRandomHex(16);

    const obj = {
        'AMOUNT': bookPrice,
        'CURRENCY': 398,
        'ORDER': order,
        'MERCH_RN_ID': merchRNID,
        'DESC': `${uid}/${bookId}`,
        'MERCHANT': 'AF3POST90033832',
        'MERCH_NAME': 'TOO KAZAKHCONTENT',
        'TERMINAL': '90033832',
        'TIMESTAMP': timestamp,
        'MERCH_GMT': '+6',
        'TRTYPE': '1',
        'BACKREF': process.env.REACT_APP_BACKREF_URL,
        'LANG': 'kz',
        'NONCE': nonce,
        'MK_TOKEN': 'MERCH',
        'NOTIFY_URL': 'https://esti.netlify.app/home',
        'CLIENT_IP': '0.0.0.0',
        'M_INFO': 'eyJicm93c2VyU2NyZWVuSGVpZ2h0IjoiMTkyMCIsImJyb3dzZXJTY3JlZW5XaWR0aCI6IjEwODAiLCJtb2JpbGVQaG9uZSI6eyJjYyI6IjciLCJzdWJzY3JpYmVyIjoiNzQ3NTU1ODg4OCJ9fQ=='
    };

    const macList = {
        'AMOUNT': 30,
        'CURRENCY': 398,
        'ORDER': order,
        'MERCHANT': 'AF3POST90033832',
        'TERMINAL': '90033832',
        'MERCH_GMT': '+6',
        'TIMESTAMP': timestamp,
        'TRTYPE': '1',
        'NONCE': nonce,
    }

    const macData = Object.values(macList).map((value) => `${value.toString().length}${value}`).join('');
    const pSign = generateHMACSignature(macData);
    obj['P_SIGN'] = pSign;

    return { data: qs.stringify(obj), orderId: order, bookPrice };
};

const generateHMACSignature = (data) => {
    const key = "bc24a347ae1f8c0a00304923f434a245";
    const shaObj = new jsSHA("SHA-1", "TEXT");
    shaObj.setHMACKey(key, "HEX");
    shaObj.update(data);
    return shaObj.getHMAC("HEX").toUpperCase();
};

const sendPaymentRequest = async (uid, bookId, bookPrice) => {
    const { data } = generatePaymentRequestData(uid, bookId, bookPrice);

    const config = {
        method: 'post',
        url: process.env.REACT_APP_PAYMENT_URL,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        data: data
    };

    try {
        const response = await axios.request(config);
        return response.data;
    } catch (error) {
        console.error('Error during payment request:', error);
        throw error;
    }
};

export default sendPaymentRequest;