import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { auth, realtimeDb } from './config';
import { onAuthStateChanged, onIdTokenChanged } from 'firebase/auth';
import { ref, set, onDisconnect } from 'firebase/database';
import SignIn from './screens/ui/SignIn';
import Main from './screens/Main';
import './App.css';

const App = () => {
  // Қолданушы туралы мәліметті сақтау үшін
  const [user, setUser] = useState(null);
  // Жүктеу индикаторы көрсетіле ме, соны сақтау үшін
  const [loading, setLoading] = useState(true);

  // Қолданушының онлайн/офлайн статусын Firebase-ке жазу
  const updateUserStatus = useCallback((user) => {
    if (user) {
      const userStatusRef = ref(realtimeDb, `status/${user.uid}`); // Қолданушы статусына арналған сілтеме

      // Онлайн күйдегі мәліметтер
      const isOnline = {
        state: 'online',
        last_changed: Date.now(),
      };

      // Офлайн күйдегі мәліметтер
      const isOffline = {
        state: 'offline',
        last_changed: Date.now(),
      };

      // Онлайн күйді Firebase-ке жазу
      set(userStatusRef, isOnline);
      // Қолданушы желіден шықса, офлайн күйді жазу
      onDisconnect(userStatusRef).set(isOffline);
    }
  }, []);

  // Аутентификация және қолданушы статусын бақылау
  useEffect(() => {
    // Егер қолданушы кэште бар болса, оны күйге орнатамыз
    const cachedUser = localStorage.getItem('user');
    if (cachedUser) {
      setUser(JSON.parse(cachedUser)); // Кэштен алынған қолданушыны күйге орнату
      setLoading(false); // Жүктеуді аяқтау
    } else {
      setLoading(true); // Егер қолданушы жоқ болса, жүктеу күйін белсенді ету
    }

    // Firebase-те аутентификация күйін тыңдау
    const unsubscribeAuth = onAuthStateChanged(auth, async (user) => {
      if (user) {
        // Қолданушы мәліметтерін кэшке сақтау
        localStorage.setItem('user', JSON.stringify(user));
      }

      // Қолданушыны күйге орнату және статусын жаңарту
      setUser(user);
      setLoading(false);
      updateUserStatus(user);
    });

    // Firebase-те токен күйін тыңдау
    const unsubscribeToken = onIdTokenChanged(auth, (user) => {
      if (!user) {
        localStorage.removeItem('user'); // Қолданушы шыққан кезде кэшті тазалау
        setUser(null);
      }
    });

    // Бақылауды тоқтату үшін функцияны қайтарамыз
    return () => {
      unsubscribeAuth();
      unsubscribeToken();
    };
  }, [updateUserStatus]);

  // Жүктеу күйінде индикатор көрсету
  if (loading) {
    return (
      <div className="spinner-container">
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={user ? <Main /> : <SignIn />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
};

export default App;