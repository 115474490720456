import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import playIcon from '../../icons/play.svg';
import paid from '../../icons/paid.svg';
import load_book from '../../icons/load_book.svg';

import '../style/BooksCard.css';

const BooksCard = ({ name, imageUrl, audioUrls, audioFileNames, price, isPaid, onPlay, onImageClick }) => {
    return (
        <div className="book_card">
            <LazyLoadImage
                src={imageUrl}
                alt={name}
                className="book_image"
                effect='black-and-white'
                onClick={onImageClick}
                placeholderSrc={load_book}
            />
            <h4 className="book_name">{name}</h4>
            {price && isPaid && <img src={paid} alt="paid" className="paid_icon" />}
            <button className="play_button" onClick={() => onPlay(audioUrls, audioFileNames)}>
                <img src={playIcon} alt="play" className="play_icon" />
            </button>
        </div>
    );
};

export default BooksCard;