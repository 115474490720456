import React, { useState, useEffect } from 'react';
import { doc, getDoc, updateDoc, collection, addDoc } from 'firebase/firestore';
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { auth, db, storage, realtimeDb } from '../../config';
import { ref as databaseRef, get } from 'firebase/database';

import { UserUI } from '../contents/UserUI';
import PayInfoModal from '../modals/PayInfoModal';
import BuyModal from '../modals/BuyModal';
import backMoneyRequest from '../functions/BackMoney';

const User = ({ handleReadButtonClick }) => {
    const [userBooks, setUserBooks] = useState([]);
    const [favoriteBooks, setFavoriteBooks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [displayName, setDisplayName] = useState('');
    const [photoUrl, setPhotoUrl] = useState('');
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [expandedSection, setExpandedSection] = useState(null);
    const [feedbackText, setFeedbackText] = useState('');
    const [selectedBook, setSelectedBook] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const userDisplayName = localStorage.getItem('displayName');
    const userPhotoUrl = localStorage.getItem('photoURL');
    const userFromLocalStorage = JSON.parse(localStorage.getItem('user'));
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [userOrders, setUserOrders] = useState([]);
    const [loadingOrders, setLoadingOrders] = useState(true);
    const [isBuyModalOpen, setIsBuyModalOpen] = useState(false);
    const [buyModalBook, setBuyModalBook] = useState(null);

    const user = auth.currentUser;

    const fetchUserOrders = async (userId) => {
        setLoadingOrders(true);
        try {
            const ordersRef = databaseRef(realtimeDb, `users/${userId}/orders`);
            const snapshot = await get(ordersRef);
            if (snapshot.exists()) {
                const ordersData = snapshot.val();
                const ordersArray = Object.entries(ordersData).map(([orderId, order]) => ({
                    orderId,
                    ...order,
                }));
                setUserOrders(ordersArray);
            } else {
                setUserOrders([]);
            }
        } catch (error) {
            console.error('Error fetching user orders:', error);
        } finally {
            setLoadingOrders(false);
        }
    };

    useEffect(() => {
        const user = auth.currentUser;
        if (user && expandedSection === 'orders') {
            fetchUserOrders(user.uid);
        }
    }, [expandedSection]);

    useEffect(() => {
        setDisplayName(userFromLocalStorage.displayName || userDisplayName);
        setPhotoUrl(userFromLocalStorage.photoURL || userPhotoUrl);

        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                const fetchUserBooks = async () => {
                    try {
                        const userDocRef = doc(db, 'users', user.uid);
                        const userDocSnap = await getDoc(userDocRef);
                        if (userDocSnap.exists()) {
                            const userData = userDocSnap.data();
                            const bookIds = userData.books || [];
                            const fetchedBooks = await Promise.all(
                                bookIds.map(async (bookId) => {
                                    const bookDocRef = doc(db, 'books', bookId);
                                    const bookDocSnap = await getDoc(bookDocRef);
                                    if (bookDocSnap.exists()) {
                                        return { id: bookId, ...bookDocSnap.data() };
                                    }
                                    return null;
                                })
                            );
                            setUserBooks(fetchedBooks.filter(book => book !== null));
                        }
                    } catch (error) {
                        console.error('Error fetching user books: ', error);
                    } finally {
                        setLoading(false);
                    }
                };

                fetchUserBooks();
            }
        });

        return () => unsubscribe();
    }, [userFromLocalStorage.displayName, userFromLocalStorage.photoURL, userDisplayName, userPhotoUrl]);

    useEffect(() => {
        if (userFromLocalStorage && userFromLocalStorage.uid) {
            const fetchFavoriteBooks = async () => {
                try {
                    const userDocRef = doc(db, 'users', userFromLocalStorage.uid);
                    const userDocSnap = await getDoc(userDocRef);
                    if (userDocSnap.exists()) {
                        const userData = userDocSnap.data();
                        const favoriteBookIds = userData.saves || [];

                        const fetchedFavoriteBooks = await Promise.all(
                            favoriteBookIds.map(async (bookId) => {
                                const bookDocRef = doc(db, 'books', bookId);
                                const bookDocSnap = await getDoc(bookDocRef);
                                if (bookDocSnap.exists()) {
                                    return { id: bookId, ...bookDocSnap.data() };
                                }
                                return null;
                            })
                        );

                        setFavoriteBooks(fetchedFavoriteBooks.filter(book => book !== null));
                    }
                } catch (error) {
                    console.error("Error fetching favorite books: ", error);
                }
            };

            fetchFavoriteBooks();
        } else {
            console.error("User not found in localStorage");
        }
    }, [userFromLocalStorage]);

    const handleCloseMenu = () => {
        if (isMenuOpen) {
            setIsMenuOpen(false);
            handleSectionToggle('');
        }
    };

    const handleReadCheckButtonClick = (book) => {
        if (user) {
            if (userBooks.some((userBook) => userBook.id === book.id) || !book.isPaid) {
                handleReadButtonClick();
                setSelectedBook(book);
                setIsModalOpen(true);
            } else {
                setBuyModalBook({
                    id: book.id,
                    title: book.title,
                    description: book.description,
                    price: book.price,
                });
                setIsBuyModalOpen(true);
            }
        } else {
            alert('Кітапты тыңдау үшін алдымен жүйеге кіруіңіз қажет.');
        }
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedBook(null);
    };

    const handleLogout = () => {
        auth.signOut().then(() => {
            localStorage.clear();
        });
    };

    const handleFileUpload = async (e) => {
        const file = e.target.files[0];
        if (file) {
            try {
                if (!user) {
                    throw new Error('No authenticated user found.');
                }
                const storag = storageRef(storage, `profilePictures/${user.uid}_${Date.now()}_${file.name}`);

                const snapshot = await uploadBytes(storag, file);

                const downloadURL = await getDownloadURL(snapshot.ref);

                const userDocRef = doc(db, 'users', user.uid);
                await updateDoc(userDocRef, {
                    photoURL: downloadURL
                });

                setPhotoUrl(downloadURL);
                localStorage.setItem('photoURL', downloadURL);

                console.log('Profile photo updated successfully.');
            } catch (error) {
                console.error('Error uploading profile photo: ', error);
            }
        }
    };

    const handleMenuClick = (e) => {
        setIsMenuOpen(!isMenuOpen);
        e.stopPropagation();
    };

    const handleSectionToggle = (section) => {
        setExpandedSection(expandedSection === section ? null : section);
    };

    const handleFeedbackChange = (e) => {
        setFeedbackText(e.target.value);
    };

    const handleFeedbackSubmit = async (e) => {
        e.preventDefault();
        try {
            const user = auth.currentUser;
            if (user) {
                const feedbackRef = collection(db, 'feedback');
                await addDoc(feedbackRef, {
                    userId: user.uid,
                    text: feedbackText,
                });

                setFeedbackText('');
                handleSectionToggle('feedback');
            }
        } catch (error) {
            console.error('Error submitting feedback: ', error);
        }
    };

    const handleRefund = async (ORDER, RRN, INT_REF, AMOUNT, MERCH_RN_ID, order, bookId) => {
        try {
            await backMoneyRequest(ORDER, RRN, INT_REF, AMOUNT, MERCH_RN_ID, user.uid, order.orderId, bookId);
            alert('Ақшаны қайтару 3 жұмыс күні ішінде орын алды. Егерде қайтаруда қиындық туындаса техникалық қызметке жүгіне аласыз.');
        } catch (error) {
            console.error(`Refund failed for order ${ORDER}:`, error);
            alert('Ақша қайтару кезінде қате пайда болды');
        }
    };

    return (
        <>
            <UserUI
                userBooks={userBooks}
                loading={loading}
                displayName={displayName}
                photoUrl={photoUrl}
                isMenuOpen={isMenuOpen}
                expandedSection={expandedSection}
                userOrders={userOrders}
                loadingOrders={loadingOrders}
                feedbackText={feedbackText}
                selectedBook={selectedBook}
                isModalOpen={isModalOpen}
                userDisplayName={userDisplayName}
                favoriteBooks={favoriteBooks}
                handleCloseMenu={handleCloseMenu}
                handleReadButtonClick={handleReadCheckButtonClick}
                closeModal={closeModal}
                handleLogout={handleLogout}
                handleFileUpload={handleFileUpload}
                handleMenuClick={handleMenuClick}
                handleSectionToggle={handleSectionToggle}
                handleFeedbackChange={handleFeedbackChange}
                handleFeedbackSubmit={handleFeedbackSubmit}
                handlePayInfoClick={() => setIsModalVisible(true)}
                handleRefund={handleRefund}
            />
            <PayInfoModal
                isOpen={isModalVisible}
                onClose={() => setIsModalVisible(false)}
            />
            <BuyModal
                isOpen={isBuyModalOpen}
                onClose={() => setIsBuyModalOpen(false)}
                bookId={buyModalBook?.id}
                bookName={buyModalBook?.title}
                bookDescription={buyModalBook?.description || ''}
                bookPrice={buyModalBook?.price || 0}
            />
        </>
    );
};

export default User;