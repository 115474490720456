import React, { useState } from 'react';
import '../../styles/modal_sign.css';
import '../../styles/pay_modal.css';
import '../style/PaymentModal.css';
import { doc, updateDoc } from 'firebase/firestore';
import { db, auth } from '../../config';

const PayInfoModal = ({ isOpen, onClose }) => {
    const [phoneNumber, setNumber] = useState('');
    const [message, setMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [agreeToTerms, setAgreeToTerms] = useState(false);
    const user = auth.currentUser;

    const handleNumberSubmit = async () => {
        if (!agreeToTerms) {
            setErrorMessage('Пайдаланушы келісімшартымен келісу керек.');
            setMessage('');
            return;
        }
        try {
            if (phoneNumber) {
                if (user) {
                    const userDocRef = doc(db, 'users', user.uid);
                    await updateDoc(userDocRef, { phoneNumber });
                    setMessage('KaspiGold нөмірі сәтті сақталды.');
                    setErrorMessage('');
                    onClose();
                } else {
                    throw new Error('Пайдаланушы анықталмады.');
                }
            } else {
                throw new Error('KaspiGold нөмірін енгізіңіз.');
            }
        } catch (error) {
            setErrorMessage(error.message);
            setMessage('');
        }
    };

    return (
        <>
            {isOpen && (
                <div className="pay_modal">
                    <div className="pay_modal_content">
                        <p className="close-modal" onClick={onClose}>&times;</p>
                        <div className="form-container">
                            <div className="pay_modal_overlay">
                                <input
                                    type="text"
                                    placeholder="KaspiGold нөміріңіз"
                                    className="pay_input_field"
                                    value={phoneNumber}
                                    onChange={(e) => setNumber(e.target.value)}
                                />
                                <div className="pay_check_content">
                                    <p className="pay_text">KaspiGold нөміріңіз сақталуына келісесіз бе?</p>
                                    <div className="pay_checkbox_container">
                                        <input
                                            type="checkbox"
                                            id="agree"
                                            checked={agreeToTerms}
                                            onChange={() => setAgreeToTerms(!agreeToTerms)}
                                        />
                                        <label htmlFor="agree">
                                            <svg className="pay_check_icon" viewBox="0 0 24 24">
                                                <path d="M20.285,6.708l-11.4,11.4a1.5,1.5,0,0,1-2.121,0l-5.4-5.4a1.5,1.5,0,0,1,2.121-2.121L7.5,15.185,18.164,4.523a1.5,1.5,0,0,1,2.121,2.185Z" fill="currentColor" />
                                            </svg>
                                        </label>
                                    </div>
                                </div>
                                <button className="save_button" onClick={handleNumberSubmit}>Сақтау</button>
                            </div>
                        </div>
                        {message && <div className="pay_modal_success">{message}</div>}
                        {errorMessage && <div className="pay_modal_error">{errorMessage}</div>}
                    </div>
                </div>
            )}
        </>
    );
};

export default PayInfoModal;