import axios from 'axios';
import qs from 'qs';
import jsSHA from 'jssha';

const pad = (num) => num.toString().padStart(2, '0');

const getTimestamp = () => {
    const t = new Date();
    return `${t.getUTCFullYear()}${pad(t.getUTCMonth() + 1)}${pad(t.getUTCDate())}${pad(t.getUTCHours())}${pad(t.getUTCMinutes())}${pad(t.getUTCSeconds())}`;
}

const generateHMACSignature = (data) => {
    const key = "bc24a347ae1f8c0a00304923f434a245";
    const shaObj = new jsSHA("SHA-1", "TEXT");
    shaObj.setHMACKey(key, "HEX");
    shaObj.update(data);
    return shaObj.getHMAC("HEX").toUpperCase();
};

const getRandomHex = (length) => {
    const characters = "ABCDEF0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
}

const backMoneyRequest = async (ORDER, RRN, INT_REF, AMOUNT, MERCH_RN_ID, USER_ID, orderId, bookId) => {
    const timestamp = getTimestamp();
    const nonce = getRandomHex(32);
    const CURRENCY = 398;

    const obj = {
        'AMOUNT': AMOUNT,
        'CURRENCY': CURRENCY,
        'ORDER': ORDER,
        'DESC': `${USER_ID}/${orderId}/${bookId}`,
        'MERCH_RN_ID': MERCH_RN_ID,
        "ORG_AMOUNT": AMOUNT,
        'RRN': RRN,
        'INT_REF': INT_REF,
        'MERCHANT': 'AF3POST90033832',
        'TERMINAL': '90033832',
        'TRTYPE': '14',
        'TIMESTAMP': timestamp,
        'MERCH_GMT': '+6',
        'NONCE': nonce,
        'LANG': 'kz',
        'BACKREF': process.env.REACT_APP_BACKMONEYREF_URL,
        'NOTIFY_URL': process.env.REACT_APP_BACKMONEYREF_URL
    };

    const macList = {
        'ORDER': ORDER,
        'ORG_AMOUNT': AMOUNT,
        'AMOUNT': AMOUNT,
        'CURRENCY': 398,
        'RRN': obj.RRN,
        'INT_REF': obj.INT_REF,
        'TERMINAL': '90033832',
        'TIMESTAMP': obj.TIMESTAMP,
        'TRTYPE': obj.TRTYPE,
        'NONCE': obj.NONCE,
    }

    const macData = Object.values(macList).map((value) => `${value.toString().length}${value}`).join('');
    const pSign = generateHMACSignature(macData);
    obj['P_SIGN'] = pSign;

    const data = qs.stringify(obj);

    const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: process.env.REACT_APP_PAYMENT_URL,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        data: data,
    };

    try {
        const response = await axios.request(config);
        if (response.data?.data?.RC === "00") {
            const backMoneyConfig = {
                method: 'post',
                maxBodyLength: Infinity,
                url: response.data?.data?.BACKREF,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                data: qs.stringify({
                    'DESC': `${USER_ID}/${orderId}/${bookId}`,
                }),
            };

            // Выполняем запрос на BACKMONEYREF_URL
            const backMoneyResponse = await axios.request(backMoneyConfig);

            // Возвращаем результат второго запроса
            return backMoneyResponse.data;
        } else {
            throw new Error(`Ошибка платежа: ${response.data?.data?.RC_TEXT}`);
        }
    } catch (error) {
        console.error('Ақшаны кері сұрау кезінде қате:', error);
        throw error;
    }
};

export default backMoneyRequest;