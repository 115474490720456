import React from 'react';

// styles
import '../../styles/user.css';

// icons
import menuIcon from '../../icons/menu.svg';
import userAltIcon from '../../icons/profile.png';

// components
import BooksCard from '../items/BooksCard';
import Read from '../ui/Read';

export function UserUI(props) {
    return (
        <div className={`home-container`}>
            {
                props.isMenuOpen &&
                <div className="menu">
                    <ul>
                        <li onClick={() => props.handleSectionToggle('profile')} className={props.expandedSection === 'profile' ? 'active' : ''}>
                            <p>Профиль</p>
                        </li>
                        <li onClick={() => props.handleSectionToggle('settings')} className={props.expandedSection === 'settings' ? 'active' : ''}>
                            <p>Баптаулар</p>
                        </li>
                        <li onClick={() => props.handleSectionToggle('help')} className={props.expandedSection === 'help' ? 'active' : ''}>
                            <p>Көмек</p>
                        </li>
                        <li onClick={() => props.handleSectionToggle('feedback')} className={props.expandedSection === 'feedback' ? 'active' : ''}>
                            <p>Кері байланыс</p>
                        </li>
                        <li onClick={() => props.handleSectionToggle('orders')} className={props.expandedSection === 'orders' ? 'active' : ''}>
                            <p>Тапсырыстар</p>
                        </li>
                    </ul>
                </div>
            }
            <div onClick={props.handleCloseMenu} className={props.isMenuOpen ? 'blur_background' : ''}>
                <main className="main_content_saves">
                    <div className="user_header">
                        <button className="menu_button" onClick={props.handleMenuClick}>
                            <img src={menuIcon} alt={userAltIcon} />
                        </button>
                        {props.photoUrl && <img src={props.photoUrl} alt="user" className="user_photo" />}
                        <h2 className="user_name">{props.displayName}</h2>
                    </div>
                </main>

                {props.favoriteBooks.length > 0 && (
                    <section className="home_section">
                        <h2 className="home_section_title">Таңдаулы</h2>
                        <div className="home_section_content">
                            {props.favoriteBooks.map(book => (
                                <BooksCard
                                    key={book.id}
                                    name={book.title}
                                    imageUrl={book.imageUrl}
                                    price={book.price}
                                    onPlay={() => props.handleReadButtonClick(book)}
                                    onImageClick={() => props.handleReadButtonClick(book)}
                                />
                            ))}
                        </div>
                    </section>
                )}

                {props.userBooks.length > 0 && (
                    <section className="home_section">
                        <h2 className="home_section_title">Сатып алынған кітаптар</h2>
                        <div className="home_section_content">
                            {props.userBooks.map(book => (
                                <BooksCard
                                    key={book.id}
                                    name={book.title}
                                    imageUrl={book.imageUrl}
                                    price={book.price}
                                    onPlay={() => props.handleReadButtonClick(book)}
                                    onImageClick={() => props.handleReadButtonClick(book)}
                                />
                            ))}
                        </div>
                    </section>
                )}

            </div>

            <footer className="footer">
                {props.expandedSection === 'profile' && <div className="settings-form">
                    <div className='settings-from-left'>
                        <img src={props.photoUrl} onClick={props.handleFileUpload} alt="Profile" className="menu-profile-photo" />
                        <h2>{props.userDisplayName}</h2>
                    </div>
                    <button className="settings-logout" onClick={props.handleLogout}>Шығу</button>
                </div>}
                {props.expandedSection === 'settings' && <form className="settings-form">
                    <button
                        className="save-button"
                        type="button"
                        onClick={props.handlePayInfoClick}
                    >
                        Төлем мәліметтерін өңдеу
                    </button>
                </form>}
                {props.expandedSection === 'feedback' && <form className="settings-form" onSubmit={props.handleFeedbackSubmit}>
                    <textarea placeholder='Мәтін' className="feedback-form-textarea" value={props.feedbackText} onChange={props.handleFeedbackChange} />
                    <button className="feedback-form-submit-button" type="submit">Жіберу</button>
                </form>}
                {props.expandedSection === 'help' && <ul class="settings-form">
                    <li class="custom-list-item"><p>Сіздің KaspiGold нөміріңіз алдағы уақытта платформаның ішіндегі ақылы аудио кітаптарды сатып алу үшін қажет.</p></li>
                    <li class="custom-list-item"><p>Аудио кітапты сатып алуға ұсыныс жасағаннан кейін сізге төлем нөмірі KaspiGold-қа жіберіледі. Төлем жасағаннан кейін аудио кітап сізге 2 минут ішінде қолжетімді болады.</p></li>
                    <li class="custom-list-item"><p>Төлем жасау нөміріңізді ауыстырғыңыз келсе баптаулар бөліміне кіріңыз.</p></li>
                    <li class="custom-list-item"><p>Аудио кітаптардың оң жақ төбесіндегі белгі кітаптың ақылы екенін білдіреді.</p></li>
                </ul>}
                {props.expandedSection === 'orders' && (
                    <section className="settings-form">
                        <h2 className="home_section_title">Менің тапсырыстарым</h2>
                        <div className="user_section_content">
                            {props.loadingOrders ? (
                                <div className="spinner"></div>
                            ) : props.userOrders.length > 0 ? (
                                props.userOrders.map((order) => {
                                    const year = parseInt(order.TIMESTAMP.substring(0, 4));
                                    const month = parseInt(order.TIMESTAMP.substring(4, 6)) - 1;
                                    const day = parseInt(order.TIMESTAMP.substring(6, 8));
                                    const hour = parseInt(order.TIMESTAMP.substring(8, 10));
                                    const minute = parseInt(order.TIMESTAMP.substring(10, 12));
                                    const second = parseInt(order.TIMESTAMP.substring(12, 14));
                                    const orderDate = new Date(year, month, day, hour, minute, second);

                                    // Calculate the difference in time
                                    const currentDate = new Date();
                                    const fourteenDaysInMs = 14 * 24 * 60 * 60 * 1000; // 14 days in milliseconds
                                    const isRefundable = currentDate - orderDate < fourteenDaysInMs;

                                    return (
                                        <div key={order.orderId} className="order-card">
                                            <h3>{order.title}</h3>
                                            <p>Бағасы: {order.AMOUNT} ₸</p>
                                            <p>Күйі: {order.RC_TEXT}</p>
                                            <p>Саны: {order.ORDER}</p>
                                            <p>Күні: {order.TIMESTAMP}</p>

                                            {isRefundable ? (
                                                <button
                                                    className="refund-button"
                                                    onClick={() => {
                                                        const descParts = order.DESC.split('/');
                                                        const bookId = descParts[1];
                                                        props.handleRefund(order.ORDER, order.RRN, order.INT_REF, order.AMOUNT, order.MERCH_RN_ID, order, bookId);
                                                    }}
                                                >
                                                    Ақшаны қайтару
                                                </button>
                                            ) : (
                                                <p>Ақшаны қайтару мерзімі өтіп кетті</p>
                                            )}
                                        </div>
                                    );
                                })
                            ) : (
                                <p>Тапсырыстар жоқ</p>
                            )}
                        </div>
                    </section>
                )}
            </footer>
            {props.isModalOpen && <Read book={props.selectedBook} closeModal={props.closeModal} />}
        </div>);
}