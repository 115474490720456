import React, { useState, useEffect } from 'react';
import '../../styles/modal_sign.css';
import '../../styles/pay_modal.css';
import { doc, getDoc } from 'firebase/firestore';
import { db, auth, realtimeDb } from '../../config';
import { ref, query, orderByChild, equalTo, get, push, set } from 'firebase/database';
import ModalSuccess from './ModalSuccess';
import sendPaymentRequest from '../functions/PaymentRequest';
// import { SEND_PUSH_URL } from '../functions/config';

const BuyModal = ({ isOpen, onClose, bookId, bookName, bookPrice, bookDescription, bookAudioCount }) => {
    const [phoneNumber, setNumber] = useState('');
    const [message, setMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isLogin, setIsLogin] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const userId = JSON.parse(localStorage.getItem('user'))?.uid;
    const [modalMessage, setModalMessage] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);
    const [paymentResponse, setPaymentResponse] = useState('');
    const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
    const [agreeNumber, setAgreeNumber] = useState(false);
    const [agreeCard, setAgreeCard] = useState(false);
    // const [token, setToken] = useState("");
    // const [title, setTitle] = useState("");
    // const [body, setBody] = useState("");

    useEffect(() => {
        const checkUserInfo = async () => {
            if (userId) {
                try {
                    const userDoc = await getDoc(doc(db, 'users', userId));
                    const userData = userDoc.exists() ? userDoc.data() : {};
                    const savedPhoneNumber = userData.phoneNumber || '';
                    setNumber(savedPhoneNumber);

                    if (!savedPhoneNumber) {
                        setIsModalVisible(true);
                        setModalMessage('Сізде ешбір төлем жолы жасалмаған');
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            } else {
                console.log('User is not authenticated');
            }
        };

        checkUserInfo();
    }, [userId]);

    // const sendNotification = async () => {
    //     try {
    //         const response = await fetch(SEND_PUSH_URL, {
    //             method: "POST",
    //             headers: {
    //                 "Content-Type": "application/json",
    //             },
    //             body: JSON.stringify({
    //                 token: token,
    //                 title: title,
    //                 body: body,
    //             }),
    //         });

    //         const data = await response.json();
    //         if (data.success) {
    //             alert("Уведомление отправлено!");
    //         } else {
    //             console.error("Ошибка:", data.error);
    //             alert("Не удалось отправить уведомление.");
    //         }
    //     } catch (error) {
    //         console.error("Ошибка:", error);
    //         alert("Ошибка при отправке запроса.");
    //     }
    // };

    const handleTabClick = (tab) => {
        setIsLogin(tab === 'kaspi');
        if (tab === 'kaspi' && !phoneNumber) {
            setModalMessage('Сізде Kaspi төлем жолы жасалмаған');
        }
    };

    const handleBuyByNumber = async () => {
        if (!agreeNumber) {
            setMessage('KaspiGold шарттарын қабылдауыңыз керек.');
            return;
        }

        if (!phoneNumber) {
            setErrorMessage('KaspiGold нөмірін енгізіңіз. \n Профил -> Баптаулар -> Төлем мәліметтерін өңдеу');
            return;
        }

        if (!bookId) {
            setErrorMessage('Кітап ID табылмады. Қайта көріңіз.');
            console.log("error")
            return;
        }

        setIsProcessing(true);

        try {
            const user = auth.currentUser;
            if (!user) {
                throw new Error('Пайдаланушы анықталмады.');
            }

            const userDocRef = doc(db, 'users', user.uid);
            const userDocSnap = await getDoc(userDocRef);

            if (userDocSnap.exists()) {
                const userData = userDocSnap.data();

                const ordersRef = ref(realtimeDb, 'orders');
                const ordersQuery = query(ordersRef, orderByChild('bookId'), equalTo(bookId));
                const ordersSnapshot = await get(ordersQuery);

                let hasOrderedSameBook = false;

                ordersSnapshot.forEach((childSnapshot) => {
                    if (childSnapshot.val().currentUserId === user.uid) {
                        hasOrderedSameBook = true;
                    }
                });

                if (hasOrderedSameBook) {
                    setModalMessage('Сіздің өтінім қаралып жатыр');
                } else {
                    const orderData = {
                        bookId: bookId,
                        currentUserId: user.uid,
                        number: userData?.phoneNumber || '',
                        bookName: bookName,
                        displayName: user.displayName || '',
                        timestamp: Date.now(),
                        status: 'pending',
                        price: bookPrice,
                    };

                    const newOrderRef = push(ordersRef);
                    await set(newOrderRef, orderData);

                    setModalMessage('Аудио кітапты төлем жасағаннан кейін тыңдай аласыңыз');
                }
                setIsModalVisible(true);
            } else {
                alert('User data not found!');
            }
        } catch (error) {
            console.error('Error processing payment:', error);
            alert('Төлем өңделгенде қате пайда болды. Қайтадан байқап көріңіз.');
        } finally {
            setIsProcessing(false);
        }
    };

    const handleBuyByCard = async (e) => {
        e.preventDefault();
        if (!agreeCard) {
            setMessage('Тіркеу шарттарын қабылдауыңыз керек.');
            return;
        }
        setIsProcessing(true);
        setPaymentResponse('');

        try {
            const user = auth.currentUser;
            if (!user) {
                throw new Error('Пайдаланушы анықталмады.');
            }

            // const tokenRef = ref(realtimeDb, `users/${user.uid}/token`);
            // const tokenSnapshot = await get(tokenRef);

            // if (!tokenSnapshot.exists()) {
            //     throw new Error('Карта төлемге дайын емес. Тіркеліңіз.');
            // }

            const response = await sendPaymentRequest(user.uid, bookId, bookPrice);

            setPaymentResponse(response);
            setIsPaymentModalOpen(true);
        } catch (error) {
            console.error('Error processing payment:', error);
            alert('Төлем өңдеу кезінде қате пайда болды. Қайта байқап көріңіз.');
        } finally {
            setIsProcessing(false);
        }
    };

    return (
        isOpen && (
            <div className="pay_modal">
                <div className='pay_modal_content'>
                    <p className="close-modal" onClick={onClose}>&times;</p>
                    {isModalVisible && <ModalSuccess message={modalMessage} onClose={() => setIsModalVisible(false)} />}
                    <div className="form-container">
                        <div className="slide-controls">
                            <input type="radio" name="slide" id="login" checked={isLogin} readOnly />
                            <input type="radio" name="slide" id="signup" checked={!isLogin} readOnly />
                            <label htmlFor="login" className="slide login" onClick={() => handleTabClick('kaspi')}>
                                Kaspi
                            </label>
                            <label htmlFor="signup" className="slide signup" onClick={() => handleTabClick('card')}>
                                Карта
                            </label>
                            <div className="slider-tab"></div>
                        </div>
                        <div>
                            {isLogin ? (
                                <div className="pay_modal_overlay">
                                    <p className="pay_modal_text">{bookName}</p>
                                    <p className="pay_modal_text">Аудиолар саны: {bookAudioCount}</p>
                                    <p className="pay_modal_text">Құны: {bookPrice}</p>
                                    <p className="pay_modal_text">Сипаттама: {bookDescription}</p>

                                    <div className="pay_check_content">
                                        <p className="pay_text">
                                            KaspiGold төлемді модератор қарайды сондықтан күтуіңіз керек
                                            Сіздің тапсырысыңыз 09:00 ден 19:00 аралығында қаралады
                                        </p>
                                        <div className="pay_checkbox_container">
                                            <input
                                                type="checkbox"
                                                id="agree"
                                                checked={agreeNumber}
                                                onChange={() => setAgreeNumber(!agreeNumber)}
                                            />
                                            <label htmlFor="agree">
                                                <svg className="pay_check_icon" viewBox="0 0 24 24">
                                                    <path d="M20.285,6.708l-11.4,11.4a1.5,1.5,0,0,1-2.121,0l-5.4-5.4a1.5,1.5,0,0,1,2.121-2.121L7.5,15.185,18.164,4.523a1.5,1.5,0,0,1,2.121,2.185Z" fill="currentColor" />
                                                </svg>
                                            </label>
                                        </div>
                                    </div>

                                    <button className="save_button" onClick={handleBuyByNumber} disabled={!agreeNumber || isProcessing}>
                                        {isProcessing ? 'Өңдеу...' : 'Сатып алу'}
                                    </button>
                                </div>
                            ) : (
                                <form onSubmit={handleBuyByCard} className="pay_modal_overlay">
                                    <p className="pay_modal_text">{bookName}</p>
                                    <p className="pay_modal_text">Аудиолар саны: {bookAudioCount}</p>
                                    <p className="pay_modal_text">Құны: {bookPrice}</p>
                                    <p className="pay_modal_text">Сипаттама: {bookDescription}</p>
                                    <div className="pay_check_content">
                                        <p className="pay_text">Тіркеу арқылы сіз біздің <a className="link" target='_blank' href='https://firebasestorage.googleapis.com/v0/b/tynda-65659.appspot.com/o/%D0%BA%D0%B0%D0%B7_%D0%B8%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%86%D0%B8%D0%B8_%D0%B4%D0%BB%D1%8F_%D1%80%D0%B0%D0%B7%D0%BC%D0%B5%D1%89%D0%B5%D0%BD%D0%B8%D1%8F_%D0%BD%D0%B0_%D1%81%D0%B0%D0%B9%D1%82%D0%B5.pdf?alt=media&token=50c33b96-fb75-4b68-9d9d-222be9db7fa6' rel="noreferrer">төлем</a> шарттармен, <a className='link' href='https://firebasestorage.googleapis.com/v0/b/tynda-65659.appspot.com/o/%D0%BA%D0%B0%D0%B7_%D0%BF%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0_%D0%BA%D0%BE%D0%BD%D1%84%D0%B8%D0%B4%D0%B5%D0%BD%D1%86%D0%B8%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D1%81%D1%82%D0%B8.pdf?alt=media&token=1ad2f376-66f5-45e8-8440-d08c4a9befee' target='_blank' rel="noreferrer">құпиялылық саясатымен</a> және cookie файлдары саясатымен келісесіз.</p>
                                        <div className="pay_checkbox_container">
                                            <input
                                                type="checkbox"
                                                id="agree"
                                                checked={agreeCard}
                                                onChange={() => setAgreeCard(!agreeCard)}
                                            />
                                            <label htmlFor="agree">
                                                <svg className="pay_check_icon" viewBox="0 0 24 24">
                                                    <path d="M20.285,6.708l-11.4,11.4a1.5,1.5,0,0,1-2.121,0l-5.4-5.4a1.5,1.5,0,0,1,2.121-2.121L7.5,15.185,18.164,4.523a1.5,1.5,0,0,1,2.121,2.185Z" fill="currentColor" />
                                                </svg>
                                            </label>
                                        </div>
                                    </div>
                                    <button type="submit" className="save_button">Сатып алу</button>
                                    {isPaymentModalOpen && (
                                        <div className="pay_card_modal">
                                            <div className="pay_modal_card_content">
                                                <button onClick={() => setIsPaymentModalOpen(false)} className="close-button">
                                                    ×
                                                </button>
                                                <div dangerouslySetInnerHTML={{ __html: paymentResponse }} />
                                            </div>
                                        </div>
                                    )}
                                </form>
                            )}
                            {errorMessage && <div className="error">{errorMessage}</div>}
                            {message && <div className="error">{message}</div>}
                        </div>
                    </div>
                </div>
            </div>
        )
    );
};

export default BuyModal;