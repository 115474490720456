import React, { useState } from 'react';

// firebase
import { auth, db } from '../../config.js';
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import { doc, setDoc } from "firebase/firestore";

// components
import ModalSign from '../modals/ModalSign.js';

// styles
import '../../styles/form.css';

// icons
import google_icon from '../../icons/google_icon.svg';
import email_icon from '../../icons/mail.svg';
import estiLogo from '../../icons/logo_content.svg';

const SignIn = () => {
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleGoogleSignIn = async () => {
        const provider = new GoogleAuthProvider();
        try {
            const result = await signInWithPopup(auth, provider);
            const user = result.user;

            await setDoc(doc(db, "users", user.uid), {
                uid: user.uid,
                displayName: user.displayName,
                email: user.email,
                photoURL: user.photoURL,
                lastLogin: new Date()
            }, { merge: true });

            document.cookie = `uid=${user.uid}; path=/; max-age=86400;`;
            document.cookie = `displayName=${user.displayName}; path=/; max-age=86400;`;
            document.cookie = `email=${user.email}; path=/; max-age=86400;`;
            document.cookie = `photoURL=${user.photoURL}; path=/; max-age=86400;`;

            navigate("/home");

        } catch (err) {
            console.error("Ошибка при входе через Google:", err);
        }
    };

    const handleEmailAuth = () => {
        setIsModalOpen(true);
    };

    return (
        <div className="signin_container">
            <div className="form">
                <img src={estiLogo} alt="estiLogo" />
                <div className="signin-form">
                    <button onClick={handleGoogleSignIn} className="btn"><img className='google_icon' src={google_icon} alt='google_icon' />Google арқылы кіріңіз</button>
                    <button onClick={handleEmailAuth} className="btn"><img className='email_icon' src={email_icon} alt='email_icon' />Email арқылы кіріңіз</button>
                </div>
            </div>
            <ModalSign isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
        </div>
    );
};

export default SignIn;